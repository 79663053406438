(function() {
    'use strict';

    function stateReader(serial) {
    
        function Entry(key, indices, isBool) {
            this.key = key;
            this.indices = indices;
            this.isBool = isBool || false;
        }

        Entry.prototype.get = function(state) {
            var pointer = state;
            for (var idx = 0; idx < this.indices.length; ++idx) {
                var v = this.indices[idx];
                if (typeof(pointer) !== 'object' || !(v in pointer)) {
                    return (this.isBool && (idx + 1 === this.indices.length)) ? 0 : '';
                }
                pointer = pointer[v];
            }
            if (this.isBool) {
                return pointer ? 1 : 0;
            }
            return pointer;
        };

        var entries = [
            new Entry('Timestamp', ['timestamp_us']),
            new Entry('Status #0', ['status', '_0'], true),
            new Entry('Status #1', ['status', '_1'], true),
            new Entry('Status #2', ['status', '_2'], true),
            new Entry('Status No Signal', ['status', 'no_signal'], true),
            new Entry('Status Idle', ['status', 'idle'], true),
            new Entry('Status Arming', ['status', 'arming'], true),
            new Entry('Status Recording SD', ['status', 'recording_sd'], true),
            new Entry('Status #7', ['status', '_7'], true),
            new Entry('Status Loop Slow', ['status', 'loop_slow'], true),
            new Entry('Status #9', ['status', '_9'], true),
            new Entry('Status Armed', ['status', 'armed'], true),
            new Entry('Status Battery Low', ['status', 'battery_low'], true),
            new Entry('Status Battery Critical', ['status', 'battery_critical'], true),
            new Entry('Status Log Full', ['status', 'log_full'], true),
            new Entry('Status Crash Detected', ['status', 'crash_detected'], true),
            new Entry('Status Override', ['status', 'override'], true),
            new Entry('V0', ['v0_raw']),
            new Entry('I0', ['i0_raw']),
            new Entry('I1', ['i1_raw']),
            new Entry('Accelerometer X', ['accel', 'x']),
            new Entry('Accelerometer Y', ['accel', 'y']),
            new Entry('Accelerometer Z', ['accel', 'z']),
            new Entry('Gyroscope X', ['gyro', 'x']),
            new Entry('Gyroscope Y', ['gyro', 'y']),
            new Entry('Gyroscope Z', ['gyro', 'z']),
            new Entry('Magnetometer X', ['mag', 'x']),
            new Entry('Magnetometer Y', ['mag', 'y']),
            new Entry('Magnetometer Z', ['mag', 'z']),
            new Entry('Temperature C', ['temperature']),
            new Entry('Pressure Pa', ['pressure']),
            new Entry('RC PPM 1', ['ppm', 0]),
            new Entry('RC PPM 2', ['ppm', 1]),
            new Entry('RC PPM 3', ['ppm', 2]),
            new Entry('RC PPM 4', ['ppm', 3]),
            new Entry('RC PPM 5', ['ppm', 4]),
            new Entry('RC PPM 6', ['ppm', 5]),
            new Entry('RC AUX mask', ['aux_chan_mask']),
            new Entry('Command Throttle', ['command', 'throttle']),
            new Entry('Command Pitch', ['command', 'pitch']),
            new Entry('Command Roll', ['command', 'roll']),
            new Entry('Command Yaw', ['command', 'yaw']),
            new Entry('Control Force Z', ['control', 'fz']),
            new Entry('Control Torque X', ['control', 'tx']),
            new Entry('Control Torque Y', ['control', 'ty']),
            new Entry('Control Torque Z', ['control', 'tz']),
            new Entry('PID Master Fz - Last Time', ['pid_master_fz', 'timestamp_us']),
            new Entry('PID Master Fz - Input', ['pid_master_fz', 'input']),
            new Entry('PID Master Fz - Setpoint', ['pid_master_fz', 'setpoint']),
            new Entry('PID Master Fz - P', ['pid_master_fz', 'p_term']),
            new Entry('PID Master Fz - I', ['pid_master_fz', 'i_term']),
            new Entry('PID Master Fz - D', ['pid_master_fz', 'd_term']),
            new Entry('PID Master Tx - Last Time', ['pid_master_tx', 'timestamp_us']),
            new Entry('PID Master Tx - Input', ['pid_master_tx', 'input']),
            new Entry('PID Master Tx - Setpoint', ['pid_master_tx', 'setpoint']),
            new Entry('PID Master Tx - P', ['pid_master_tx', 'p_term']),
            new Entry('PID Master Tx - I', ['pid_master_tx', 'i_term']),
            new Entry('PID Master Tx - D', ['pid_master_tx', 'd_term']),
            new Entry('PID Master Ty - Last Time', ['pid_master_ty', 'timestamp_us']),
            new Entry('PID Master Ty - Input', ['pid_master_ty', 'input']),
            new Entry('PID Master Ty - Setpoint', ['pid_master_ty', 'setpoint']),
            new Entry('PID Master Ty - P', ['pid_master_ty', 'p_term']),
            new Entry('PID Master Ty - I', ['pid_master_ty', 'i_term']),
            new Entry('PID Master Ty - D', ['pid_master_ty', 'd_term']),
            new Entry('PID Master Tz - Last Time', ['pid_master_tz', 'timestamp_us']),
            new Entry('PID Master Tz - Input', ['pid_master_tz', 'input']),
            new Entry('PID Master Tz - Setpoint', ['pid_master_tz', 'setpoint']),
            new Entry('PID Master Tz - P', ['pid_master_tz', 'p_term']),
            new Entry('PID Master Tz - I', ['pid_master_tz', 'i_term']),
            new Entry('PID Master Tz - D', ['pid_master_tz', 'd_term']),
            new Entry('PID Slave Fz - Last Time', ['pid_slave_fz', 'timestamp_us']),
            new Entry('PID Slave Fz - Input', ['pid_slave_fz', 'input']),
            new Entry('PID Slave Fz - Setpoint', ['pid_slave_fz', 'setpoint']),
            new Entry('PID Slave Fz - P', ['pid_slave_fz', 'p_term']),
            new Entry('PID Slave Fz - I', ['pid_slave_fz', 'i_term']),
            new Entry('PID Slave Fz - D', ['pid_slave_fz', 'd_term']),
            new Entry('PID Slave Tx - Last Time', ['pid_slave_tx', 'timestamp_us']),
            new Entry('PID Slave Tx - Input', ['pid_slave_tx', 'input']),
            new Entry('PID Slave Tx - Setpoint', ['pid_slave_tx', 'setpoint']),
            new Entry('PID Slave Tx - P', ['pid_slave_tx', 'p_term']),
            new Entry('PID Slave Tx - I', ['pid_slave_tx', 'i_term']),
            new Entry('PID Slave Tx - D', ['pid_slave_tx', 'd_term']),
            new Entry('PID Slave Ty - Last Time', ['pid_slave_ty', 'timestamp_us']),
            new Entry('PID Slave Ty - Input', ['pid_slave_ty', 'input']),
            new Entry('PID Slave Ty - Setpoint', ['pid_slave_ty', 'setpoint']),
            new Entry('PID Slave Ty - P', ['pid_slave_ty', 'p_term']),
            new Entry('PID Slave Ty - I', ['pid_slave_ty', 'i_term']),
            new Entry('PID Slave Ty - D', ['pid_slave_ty', 'd_term']),
            new Entry('PID Slave Tz - Last Time', ['pid_slave_tz', 'timestamp_us']),
            new Entry('PID Slave Tz - Input', ['pid_slave_tz', 'input']),
            new Entry('PID Slave Tz - Setpoint', ['pid_slave_tz', 'setpoint']),
            new Entry('PID Slave Tz - P', ['pid_slave_tz', 'p_term']),
            new Entry('PID Slave Tz - I', ['pid_slave_tz', 'i_term']),
            new Entry('PID Slave Tz - D', ['pid_slave_tz', 'd_term']),
            new Entry('Motor 1', ['motor_out', 0]),
            new Entry('Motor 2', ['motor_out', 1]),
            new Entry('Motor 3', ['motor_out', 2]),
            new Entry('Motor 4', ['motor_out', 3]),
            new Entry('Motor 5', ['motor_out', 4]),
            new Entry('Motor 6', ['motor_out', 5]),
            new Entry('Motor 7', ['motor_out', 6]),
            new Entry('Motor 8', ['motor_out', 7]),
            new Entry('Kinematics Pitch', ['kinematics_angle', 'pitch']),
            new Entry('Kinematics Roll', ['kinematics_angle', 'roll']),
            new Entry('Kinematics Yaw', ['kinematics_angle', 'yaw']),
            new Entry('Kinematics Pitch Velocity', ['kinematics_rate', 'pitch']),
            new Entry('Kinematics Roll Velocity', ['kinematics_rate', 'roll']),
            new Entry('Kinematics Yaw Velocity', ['kinematics_rate', 'yaw']),
            new Entry('Kinematics Altitude', ['kinematics_altitude']),
            new Entry('Loop Count', ['loop_count']),
        ];

        function readHeader() {
            return entries.map(function(v) { return v.key; }).join(',');
        }

        function stateToRow(state) {
            return entries.map(function(v) {
                return v.get(state).toString();
            }).join(',');
        }
        
        var loaded_states;
        
        var backend = new serial.Backend();
        
        backend.busy = function() { return false; };
        backend.send = function() { console.error('Unexpected call to serial.send()'); };
        serial.setBackend(backend);
        serial.addOnReceiveCallback(function(messageType, state) {
            if (messageType !== 'State') {
                return;
            }
            loaded_states = loaded_states + stateToRow(state) + '\n';
        });
        
        function parse(data) {
            loaded_states = readHeader() + '\n';
            backend.onRead(new Uint8Array(data));
        }
        
        function getHistory() {
            return loaded_states;
        }
        
        function getEntries() {
            return entries;
        }
           
        return {
            parse: parse,
            getHistory: getHistory,
            getEntries: getEntries,
        };
    }
    angular.module('flybrixFlightRecorder').factory('stateReader', ['serial', stateReader]);
    
}());
    
